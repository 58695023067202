import React from 'react';
import classnames from 'classnames';

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton, { PrimaryButton } from 'components/uiLibrary/LinkButton';
import Tooltip from 'components/uiLibrary/Tooltip';
import useScrollIntoView from 'utils/hooks/useScrollIntoView';
import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';

import classes from './PlanCard.module.scss';

const PlanCard = ({
  price,
  description,
  ctaText,
  features = [],
  isCurrentPlan,
  topActionText,
  onSelect,
  additionalPriceInfo,
  headerCta,
  headerCtaAction,
  title,
  actionButtonVariant,
  subActionOnClick,
  subActionCtaText,
  mobileCtaSubTitle,
  redirectCta,
  redirectCtaAction,
  multiFeature = false,
  actionText,
  isExpanded,
  onToggle,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isMobile = useMediaQuery('(max-width:1024px)');
  const { ref: scrollRef } = useScrollIntoView({ enabled: isExpanded, resizeObserver: true });
  const toggleAccordion = () => {
    onToggle();
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onToggle();
    }
  };

  return (
    <div className={classes.planCard}>
      <div
        className={classnames(classes.planCard__header, {
          [classes.planCard__header_expanded]: isExpanded,
        })}
        role="button"
        tabIndex="0"
        onClick={isMobile ? toggleAccordion : () => {}}
        onKeyDown={isMobile ? handleKeyPress : () => {}}
      >
        <div className={classes.planCard__headerTop}>
          {price && (
            <Typography size="40" className={classes.planCard__price} color="orange">
              {price}
            </Typography>
          )}
          <div className={classes.planCard__headerBottom_mobile}>
            {topActionText && isMobile && (
              <LinkButton
                onClick={onSelect}
                className={classnames(classes.planCard__action, classes.planCard__action_top)}
                variant={actionButtonVariant}
                isV4
                rightIcon={<SpriteIcon icon="chevron_right" size={16} className={classes.expandMore} />}
              >
                <Typography size="12" color="link" weight="bold">
                  {t(topActionText)}
                </Typography>
              </LinkButton>
            )}
            {mobileCtaSubTitle && isMobile && (
              <Typography size="12" className={classes.textOverflow}>
                ({t(mobileCtaSubTitle)})
              </Typography>
            )}
          </div>
          {ctaText && !isMobile ? (
            <LinkButton
              rightIcon={<SpriteIcon icon="chevron_right" size={16} className={classes.expandMore} />}
              variant="text"
              onClick={e => {
                e.stopPropagation();
              }}
              styles={{ root: classes.planCard__cta }}
            >
              {t(ctaText)}
            </LinkButton>
          ) : (
            description &&
            !isMobile && (
              <Typography
                size="10"
                color="secondary"
                className={classnames(classes.planCard__description, {
                  [classes.planCard__description_fullWidth]: !price,
                })}
              >
                {t(description)}
              </Typography>
            )
          )}

          {headerCta && isExpanded && (
            <div className={classes.planCard__ctaBadge}>
              <LinkButton
                onClick={e => {
                  e.stopPropagation();
                  headerCtaAction();
                }}
                variant="text"
                styles={{ root: classes.planCard__ctaBadge_ctaText }}
              >
                {t(headerCta)}
              </LinkButton>
            </div>
          )}
        </div>
        <div className={classes.planCard__planInfo}>
          {title && !isMobile && (
            <PrimaryButton
              onClick={onSelect}
              shape="rectangle"
              rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
            >
              {t(title)}
            </PrimaryButton>
          )}
          {additionalPriceInfo && (
            <Typography size={12} color="secondary">
              {t(additionalPriceInfo)}
            </Typography>
          )}
          {isCurrentPlan && (
            <Typography className={classes.badge} size={10} color="white">
              {t(`CURRENT_PLAN`)}
            </Typography>
          )}
          {isMobile && (
            <Typography
              size="10"
              color="secondary"
              className={classnames(classes.planCard__description, classes.planCard__description_fullWidth)}
            >
              {t(description)}
            </Typography>
          )}
        </div>
        <div>
          {redirectCta && (
            <LinkButton
              size="14"
              color="link"
              variant="text"
              onClick={redirectCtaAction}
              rightIcon={<SpriteIcon icon="chevron_right" size={10} />}
            >
              <Typography size="12" color="link">
                {t(redirectCta)}
              </Typography>
            </LinkButton>
          )}
        </div>
        {isMobile && (
          <div className={classes.planCard__mobileExpandIcon}>
            <LinkButton variant="text">{t(`${TP}.VIEW_FEATURES`)}</LinkButton>
          </div>
        )}
      </div>
      {isExpanded && (
        <>
          <div className={classes.planCard__content} ref={scrollRef}>
            {!multiFeature && (
              <ul className={classes.planCard__features}>
                {features.map((feature, index) => (
                  <li key={index} className={classes.planCard__features_list}>
                    <SpriteIcon icon={feature.icon} size={16} />
                    <Typography size="12">{t(feature.label)}</Typography>
                  </li>
                ))}
              </ul>
            )}
            {multiFeature && (
              <div className={classnames(classes.planCard__features, classes.planCard__featuresTableWrap)}>
                <table className={classes.planCard__featuresTable}>
                  <thead>
                    <tr>
                      <th>€0</th>
                      <th>€15</th>
                      <th>€120</th>
                    </tr>
                  </thead>
                  <tbody>
                    {features.map((feature, index) => (
                      <tr key={index} className={classes.planCard__featuresRow}>
                        <td className={classes.planCard__featuresRow__icon}>
                          <SpriteIcon icon={feature.free} size={12} />
                        </td>
                        <td className={classes.planCard__featuresRow__icon}>
                          <SpriteIcon icon={feature.monthly} size={12} />
                        </td>
                        <td className={classes.planCard__featuresRow__icon}>
                          <SpriteIcon icon={feature.annual} size={12} />
                        </td>
                        <td>
                          <Tooltip title={t(feature.label)}>
                            <div>
                              <Typography size="12" className={classes.label}>
                                {t(feature.label)}
                              </Typography>
                            </div>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className={classes.planCard__footer}>
            {actionText && isMobile && (
              <LinkButton
                onClick={onSelect}
                className={classes.planCard__action}
                variant={actionButtonVariant}
                rightIcon={<SpriteIcon icon="chevron_right" />}
                isV4
              >
                {t(actionText)}
              </LinkButton>
            )}
            {subActionCtaText && !isMobile && (
              <LinkButton
                onClick={subActionOnClick}
                className={classes.planCard__action}
                variant="text"
                rightIcon={<SpriteIcon icon="chevron_right" size="10" />}
              >
                <Typography size="10" color="secondary">
                  {t(subActionCtaText)}
                </Typography>
              </LinkButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PlanCard;
