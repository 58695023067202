import React, { useState } from 'react';
import classnames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Testimonials from 'components/Globals/Testimonials';
import { FEATURES } from 'components/Artist/common/ArtistPlanComparison/ArtistPlanComparison';

import PlanCard from 'components/Globals/PlansCard';
import useTracking from 'components/Globals/Analytics';
import Modal from 'components/uiLibrary/Modal';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import ArtistPlanComparison from 'components/Artist/common/ArtistPlanComparison';
import CustomerLogos from 'components/Globals/CustomerLogos';
import Image from 'components/uiLibrary/Image';
import { COMPONENTS, SECTIONS, SUB_COMPONENTS, CUSTOM_EVENT_TYPES } from 'components/Globals/Analytics/constants';
import { useCreateRegistrationMutation } from 'containers/Accounts/queries';
import {
  BASIC_PLAN_FEATURES,
  ARCHIVE_PLAN_FEATURES,
  CASTING_TOOL_FEATURES,
  SUBSCRIPTION_PRODUCT_IDS,
  CHECKOUT_TYPES,
} from 'containers/Accounts/constants';

import {
  TP,
  BASE_PAGE_ROUTES,
  ENTITY_TYPE,
  PLANS,
  STEP_VALUES,
  STEPS_LOGIN,
  URL_STATIC_PATHS,
  CUSTOMER_LOGO_SCROLL_ID,
  LOGIN_SOURCE,
} from 'constants/index';
import route from 'constants/routes';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';

import { useProfileAccesses, useUserData } from 'utils/hooks/useAuthenticatedUser';
import { useTranslation, Trans } from 'src/i18n';
import LogoImage from 'public/images/logo.png';
import classes from './LoggedInPaywall.module.scss';

const createPlanFeatures = (features, explorePlanToggle = false) =>
  features.map(feature => ({
    ...feature,
    ...(!feature?.supportedPlanAnnually && {
      icon: feature.disabled ? 'lock' : feature.icon || 'done',
    }),
    disabled: feature.disabled || explorePlanToggle,
    free: feature?.supportedPlanAnnually?.includes(PLANS.FREE) ? 'done' : 'lock',
    monthly: feature?.supportedPlanAnnually?.includes(PLANS.MONTHLY) ? 'done' : 'lock',
    annual: feature?.supportedPlanAnnually?.includes(PLANS.PRO) ? 'done' : 'lock',
  }));

const featureComparisonSection = {
  section: SECTIONS.PAYWALL_LOGGED_IN,
  component: COMPONENTS.FEATURE_COMPARISON_LIST,
};

const RightSection = ({ isMobile, t }) => {
  const { navigate } = usePageContext();
  return (
    <>
      {!isMobile && (
        <div className={classes.mainLogoSection}>
          <div className={classes.mainLogoSection__logoImage}>
            <Image src={LogoImage} alt="Operabase Home" height={14} width={140} />
            <Typography size="12" className={classnames(classes.mainLogoSection__logoImage_sinceTitle)}>
              {t(`${TP}.LP_SINCE`)}
            </Typography>
            <Typography size="12">
              <Trans
                i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
                ns="NS_APP_GLOBALS"
                components={{
                  ul: <ul className={classes.listWorks} />,
                  li: <li />,
                }}
              />
            </Typography>
          </div>
        </div>
      )}
      {!isMobile && (
        <Typography className={classes.mobileHeader__title} size="14" weight="bold">
          {t(`${TP}.TRUSTED_BY_PERFORMING_ARTS`)}
        </Typography>
      )}
      {!isMobile && (
        <LinkButton
          styles={{ root: classes.viewCustomerListLink }}
          variant="text"
          target="_blank"
          {...navigate.getLinkProps({
            baseRoute: BASE_PAGE_ROUTES.PRO,
            path: URL_STATIC_PATHS.COMPANIES,
            scrollTo: CUSTOMER_LOGO_SCROLL_ID,
          })}
          rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
          trackingData={{
            ...featureComparisonSection,
            subComponent: COMPONENTS.VIEW_CUSTOMER_LIST,
            meta: {
              path: route.INTRO_AOS,
            },
          }}
          isLink
        >
          {t(`${TP}.FN_VIEW_CUSTOMER_LIST`)}
        </LinkButton>
      )}
      {!isMobile && (
        <div className={classes.testimonialsContainer}>
          <Testimonials limit={4} hideTitle />
        </div>
      )}
    </>
  );
};

const LoggedInPaywall = ({ onCloseHandler = () => {}, hasActiveSubscription = false, hasAnnualSubscription }) => {
  const track = useTracking();
  const userData = useUserData();
  const isMobile = useMediaQuery('(max-width:1024px)');
  const { setIsLoginDialog, setShowProfileSelectorForCastingTool } = useDialogs();
  const [explorePlanToggle] = useState(false);
  const [expandedPlan, setExpandedPlan] = useState(!isMobile);
  const [isComparePlanModal, setIsComparePlanModal] = useState(false);
  const { t } = useTranslation('NS_APP_GLOBALS');

  const { ownerProfile } = useProfileAccesses();
  const { profileData, entityType } = useGetTargetProfile();

  const { navigate } = usePageContext();
  const { setSubscriptionNudgesPopup } = useNotificationPopup();
  const hasActiveProfile = profileData?.id || ownerProfile?.id;

  const isProfileIncomplete = !userData?.firstName?.trim() || !userData?.lastName?.trim();

  const handleToggle = planId => {
    setExpandedPlan(prev => (prev === planId ? null : planId));
  };

  const { mutate: createRegistrationMutation } = useCreateRegistrationMutation({
    onSuccess: response => {
      navigate.to(
        navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.REGISTER,
          queryParams: { id: response?.data?.id },
          onlyLinkProps: true,
        }),
      );
    },
  });

  const createRegistration = () => {
    createRegistrationMutation();
  };

  const basicOnSelectHandler = () => {
    if (hasActiveProfile) {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.BASIC_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CLOSE_PAYWALL,
        },
      });
      onCloseHandler();
    } else {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.BASIC_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CREATE_REGISTRATION,
        },
      });
      createRegistration();
    }
  };

  const triggerLoginDialog = () => {
    setIsLoginDialog({
      isOpen: true,
      step: STEP_VALUES[STEPS_LOGIN.REGISTRATION_UPDATE],
      source: LOGIN_SOURCE.MANDATORY_NAME,
    });
  };

  const archiveOnSelectHandler = () => {
    if (isProfileIncomplete) {
      triggerLoginDialog();
      return;
    }
    if (hasActiveProfile) {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.ARCHIVE_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.ARCHIVE_SUBSCRIPTION,
        },
      });
      setSubscriptionNudgesPopup({
        isOpen: true,
        isArchive: true,
        checkoutType: CHECKOUT_TYPES.ARCHIVE,
        subscriptionId: SUBSCRIPTION_PRODUCT_IDS.ARCHIVE_ACCESS,
        targetProfileIdToUpgrade: ownerProfile?.profile?.id || profileData?.id,
      });
    } else {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.ARCHIVE_PLAN_CARD,
        subComponent: SUB_COMPONENTS.CONTINUE_CTA,
        meta: {
          actionType: CUSTOM_EVENT_TYPES.CREATE_REGISTRATION,
        },
      });
      createRegistration();
    }
  };

  const industryProfessionalOnSelectHandler = e => {
    e.stopPropagation();

    const trackAction = (subComponent, actionType) => {
      track.click({
        section: SECTIONS.PAYWALL_LOGGED_IN,
        component: COMPONENTS.INDUSTRY_PROFESSIONAL_CARD,
        subComponent,
        meta: {
          actionType,
        },
      });
    };

    if (isProfileIncomplete) {
      return triggerLoginDialog();
    }

    if (!hasActiveProfile) {
      trackAction(SUB_COMPONENTS.CONTINUE_CTA, CUSTOM_EVENT_TYPES.CREATE_REGISTRATION);
      return createRegistration();
    }

    const handleProfileAction = (subComponent, callback) => {
      trackAction(subComponent, CUSTOM_EVENT_TYPES.CREATE_SUBSCRIPTION);
      return callback();
    };

    const actions = {
      [ENTITY_TYPE.PROFILE]: () =>
        handleProfileAction(SUB_COMPONENTS.CONTINUE_CTA, () => {
          if (!explorePlanToggle) {
            setShowProfileSelectorForCastingTool({ isOpen: true, source: 'profileAccess' });
          } else {
            onCloseHandler();
            if (ownerProfile?.profile.validationStatus?.id === 5) {
              return;
            }
            navigate.to(
              navigate.getLinkProps({
                entity: ownerProfile?.profile,
                entityType: ENTITY_TYPE.ARTIST,
                onlyLinkProps: true,
              }),
            );
          }
        }),
    };

    if (!actions[entityType]) {
      trackAction(SUB_COMPONENTS.CONTINUE_CTA, CUSTOM_EVENT_TYPES.CREATE_REGISTRATION);
      return createRegistration();
    }

    return actions[ENTITY_TYPE.PROFILE]();
  };

  const castingToolOnSelectHandler = () => {
    if (isProfileIncomplete) {
      triggerLoginDialog();
      return;
    }
    setIsLoginDialog({ isOpen: true, step: STEP_VALUES[STEPS_LOGIN.CASTING_TOOL_ACCESS] });
  };

  const freeVsPaidCTA = () => {
    track.click({
      section: SECTIONS.PAYWALL_LOGGED_IN,
      component: COMPONENTS.INDUSTRY_PROFESSIONAL_CARD,
      subComponent: SUB_COMPONENTS.FREE_VS_PAID_CTA,
    });
    setIsComparePlanModal(true);
  };

  const addLabelsToFeatures = features =>
    features.map(feature => ({
      ...feature,
      label: feature.name,
    }));

  const plans = [
    ...(!hasAnnualSubscription
      ? [
          {
            id: 'basic',
            title: hasActiveSubscription ? `${TP}.m_CONTINUE` : `${TP}.CONTINUE_FREE_PAYWALL_CTA`,
            price: '€0',
            features: createPlanFeatures(BASIC_PLAN_FEATURES),
            actionButtonVariant: 'text',
            actionText: `${TP}.CONT_FOR_FREE_BTN`,
            onSelect: basicOnSelectHandler,
            topActionText: `${TP}.CONTINUE_FREE_PAYWALL_CTA`,
            mobileCtaSubTitle: `${TP}.FREE_PLAN_OPTIONS`,
          },
        ]
      : []),
    {
      id: 'archiveAccess',
      title: `${TP}.ARCHIVE_ACCESS_TITLE`,
      price: '€10',
      description: `${TP}.PER_MONTH_BILLED_ANNUAL`,
      features: createPlanFeatures(ARCHIVE_PLAN_FEATURES),
      actionButtonVariant: 'text',
      actionText: `${TP}.m_CONTINUE`,
      onSelect: archiveOnSelectHandler,
      topActionText: `${TP}.ARCHIVE_ACCESS_TITLE`,
      mobileCtaSubTitle: `${TP}.FOR_ARCHIVE_SUB_TITLE_TEXT`,
    },
    {
      id: 'industryProfessional',
      title: isMobile ? `${TP}.ARTIST_PRO_INDUSTRY_PRO` : `${TP}.PAYMENTS_PRODUCT_ARTIST_PRO`,
      price: '€10',
      headerCta: `${TP}.FREE_MONTHLY_ANNUAL`,
      profileNameForSubText: `UP_FOR`,
      profileNameForSubscription: `Industry professional`,
      redirectCta: `${TP}.VIEW_PROFESSIONS`,
      redirectCtaAction: () => {
        onCloseHandler();
        navigate.to(
          navigate.getLinkProps({
            baseRoute: BASE_PAGE_ROUTES.ARTISTS,
            onlyLinkProps: true,
          }),
        );
      },
      headerCtaAction: () => {},
      description: `${TP}.PER_MONTH_BILLED_ANNUAL`,
      features: createPlanFeatures(addLabelsToFeatures(FEATURES)),
      actionButtonVariant: 'text',
      actionText: `${TP}.m_CONTINUE`,
      subActionOnClick: freeVsPaidCTA,
      subActionCtaText: isMobile ? `${TP}.FREE_VS_PAID` : '',
      onSelect: industryProfessionalOnSelectHandler,
      topActionText: isMobile ? `${TP}.ARTIST_PRO_INDUSTRY_PRO` : `${TP}.PAYMENTS_PRODUCT_ARTIST_PRO`,
      mobileCtaSubTitle: `${TP}.INDUSTRY_PROFESSION_POINTS`,
      tablePrice: {
        freePrice: '0',
        paidPrice: '€10',
      },
      multiFeature: true,
    },
    {
      id: 'castingTool',
      price: '€125',
      title: `${TP}.CASTING_TOOL_PAYWALL_TITLE`,
      description: `${TP}.PER_MONTH_BILLED_ANNUAL`,
      features: createPlanFeatures(CASTING_TOOL_FEATURES),
      actionButtonVariant: 'text',
      actionText: `${TP}.m_CONTINUE`,
      onSelect: castingToolOnSelectHandler,
      topActionText: `${TP}.CASTING_TOOL_PAYWALL_TITLE`,
      mobileCtaSubTitle: `${TP}.CASTING_TOOL_PLAN_CARD_TXT`,
    },
  ];

  return (
    <div className={classes.loggedInPaywall}>
      <div className={classes.loggedInPaywall__header}>
        {isMobile && (
          <div className={classes.mobileHeader}>
            <div className={classes.mainLogoSection}>
              <div className={classes.mainLogoSection__closeBtn}>
                <LinkButton variant="text" leftIcon={<SpriteIcon icon="close" size={18} />} onClick={onCloseHandler} />
              </div>
              <div className={classes.mainLogoSection__logoImage}>
                <Image src={LogoImage} alt="Operabase Home" height={14} width={140} />
                <Typography size="12" className={classnames(classes.mainLogoSection__logoImage_sinceTitle)}>
                  {t(`${TP}.LP_SINCE`)}
                </Typography>
              </div>
              <Typography className={classes.mobileHeader__title} size="14" weight="bold">
                {t(`${TP}.TRUSTED_BY_PERFORMING_ARTS`)}
              </Typography>
            </div>
            <div>
              <div className={classes.btt__customer}>
                <HorizontalScrollIndicators
                  styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
                  triggerSize={0}
                  isArrowsEnabled
                >
                  <Testimonials hideTitle limit={10} inLine />
                </HorizontalScrollIndicators>
              </div>

              <div className={classes.btt__testimonials}>
                <CustomerLogos
                  className={classes.trustedByBlock}
                  rowHeight={40}
                  showTitle={false}
                  {...(isMobile && {
                    allowShowAll: false,
                    count: 20,
                    hasLogosViewAll: true,
                  })}
                />
              </div>
              <div className={classes.separator}></div>
              <div className={classes.planChooseTitle}>
                <Typography size="18" weight="bold">
                  {t(`${TP}.CHOOSE_TO_CONTINUE_TITLE`)}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.loggedInPaywall__content}>
        <div className={classes.loggedInPaywall__right}>
          <RightSection isMobile={isMobile} t={t} />
        </div>
        <div className={classes.loggedInPaywall__left}>
          <div className={classes.loggedInPaywall__leftHeader}></div>
          <div className={classes.plans}>
            {plans.map(plan => (
              <PlanCard
                price={plan.price}
                features={plan.features}
                ctaText={plan.ctaText}
                title={plan.title}
                actionText={plan.actionText}
                onSelect={plan.onSelect}
                key={plan.id}
                description={plan.description}
                headerCta={plan.headerCta}
                headerCtaAction={plan.headerCtaAction}
                actionButtonVariant={plan.actionButtonVariant}
                subActionOnClick={plan?.subActionOnClick}
                subActionCtaText={plan?.subActionCtaText}
                profileNameForSubscription={plan?.profileNameForSubscription}
                profileNameForSubText={plan?.profileNameForSubText}
                topActionText={plan?.topActionText}
                mobileCtaSubTitle={plan?.mobileCtaSubTitle}
                hideMobileSubTitle={plan?.hideMobileSubTitle}
                redirectCta={plan?.redirectCta}
                redirectCtaAction={plan?.redirectCtaAction}
                multiFeature={plan?.multiFeature}
                tablePrice={plan?.tablePrice}
                isExpanded={isMobile ? expandedPlan === plan.id : true}
                onToggle={() => handleToggle(plan.id)}
              />
            ))}
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className={classes.btt__testimonials}>
          <CustomerLogos
            className={classes.trustedByBlock}
            rowHeight={40}
            showTitle
            {...(isMobile && {
              allowShowAll: false,
              count: 20,
              hasLogosViewAll: true,
            })}
          />
        </div>
      )}
      <ComparePlanModal isOpen={isComparePlanModal} setIsComparePlanModal={setIsComparePlanModal} />
    </div>
  );
};

const ComparePlanModal = ({ isOpen, setIsComparePlanModal }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isMobile } = useDeviceTypeLayouts();
  const handleOnClose = () => setIsComparePlanModal(false);
  return (
    <Modal
      isOpen={isOpen}
      styles={{
        modalContainer: classes.comparePlanModalContainer,
        icon: classes.intermediateRedMaskModalContainer__modalIcon,
      }}
      onClose={handleOnClose}
      allowMobileDrawer
    >
      <div className={classes.comparePlanModal}>
        <div className={classes.comparePlanModal__main}>
          <div className={classes.comparePlanModal__header}>
            <LinkButton
              variant="text"
              leftIcon={<SpriteIcon size={16} icon="modal_close" />}
              onClick={handleOnClose}
              styles={{ root: classnames(classes.closeBtn) }}
              preventDefault
              stopPropagation
              disableHover
              disableUnderline
            >
              <Typography size="12" weight="medium">
                {t(`${TP}.FN_CLOSE`)}
              </Typography>
            </LinkButton>
          </div>
          <ArtistPlanComparison onBackClickHandler={handleOnClose} isOpen={isMobile} />
        </div>
      </div>
    </Modal>
  );
};

export default LoggedInPaywall;
