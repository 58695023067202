import { useCallback, useEffect, useRef } from 'react';

const useScrollIntoView = ({
  enabled = true,
  onMount = true,
  resizeObserver = false,
  behavior = 'smooth',
  block = 'center',
  inline,
}) => {
  const ref = useRef(null);

  const scrollIntoView = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior,
        block,
        inline,
      });
    }
  }, [behavior, block, inline]);

  useEffect(() => {
    if (enabled && onMount) {
      scrollIntoView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, scrollIntoView]);

  useEffect(() => {
    let observer;

    if (enabled && resizeObserver && ref.current) {
      observer = new ResizeObserver(scrollIntoView);
      observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, scrollIntoView]);

  return {
    ref,
    scrollIntoView,
  };
};

export default useScrollIntoView;
